import axios from "axios";
import { getSessionItem, getToken } from "./auth/utils/storage";
import { checkoutServiceBaseUrl } from "./config";
import { CUSTOMER_PAGE_ROUTES, MERCHANT_PAGE_ROUTES } from "./constants";
import { logger } from "./logger";
import { getIdToken } from "./auth/utils/oktaAuth";
import { ACTIVE_MERCHANT_ID } from "./auth/constants";

const defaultAxios = () => {
  const defaultOptions = {
    baseURL: checkoutServiceBaseUrl,
    headers: {
      "Content-Type": "application/json",
    },
  };

  let axiosInstance = axios.create(defaultOptions);

  return axiosInstance;
};

const withAuthInstance = (logoutRoute) => {
  const defaultOptions = {
    baseURL: checkoutServiceBaseUrl,
    headers: {
      "Content-Type": "application/json",
    },
  };

  // Create instance
  let axiosInstance = axios.create(defaultOptions);

  // Set the AUTH token for any request
  axiosInstance.interceptors.request.use(async (config) => {
    const accessToken = await getToken();
    const idToken = await getIdToken();

    config.headers["Authorization"] = accessToken ? `Bearer ${accessToken}` : "";
    config.headers["id-token"] = idToken ? `Bearer ${idToken}` : "";

    return config;
  });

  axiosInstance.interceptors.response.use(
    // handles 200 status code
    (response) => {
      return response;
    },
    // handle 401 and 403 errors
    async (error) => {
      logger.error(error);
      const activeMerchant = getSessionItem(ACTIVE_MERCHANT_ID);
      if ([401, 403].includes(error?.response?.status) && activeMerchant) {
        return error;
      }
      if ([401, 403].includes(error?.response?.status)) {
        window.location.href = logoutRoute;
      }

      return error;
    }
  );

  return axiosInstance;
};

export default {
  axios: defaultAxios(),
  withMerchantAuth: withAuthInstance(MERCHANT_PAGE_ROUTES.Logout),
  withCustomerAuth: withAuthInstance(CUSTOMER_PAGE_ROUTES.Logout),
};
