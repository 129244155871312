export const CURRENT_USER = "CURRENT_USER";
export const ACTIVE_MERCHANT_ID = "ACTIVE_MERCHANT_ID";
export const AUTH_MODE = "AUTH_MODE";
export const OKTA_TOKEN = "okta-token-storage";
export const TOKEN = "token";

export const MODE = {
  DEFAULT: "default",
  CUSTOMER: "customer",
  MERCHANT: "merchant",
};

export const ERROR_TYPES = {
  PROFILE_FETCH_FAILED: "profile-fetch-failed",
};

export const OKTA_STATUS = {
  SUCCESS: "SUCCESS",
};

export const OKTA_ERROR = {
  AUTH_ERROR: "AuthApiError",
};

export const USER_CLAIM = {
  ORDERS_READ: "orders.read",
  ORDERS_CREATE: "orders.create",
  ORDERS_UPDATE: "orders.update",
  ANALYTICS_READ: "analytics.read",
  SLA_READ: "sla.read",
  STATEMENTS_READ: "statements.read",
};
