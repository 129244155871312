import { isBrowser } from "../../../utils";
import {
  ACTIVE_MERCHANT_ID,
  CURRENT_USER,
  MODE,
  OKTA_TOKEN,
  TOKEN,
} from "../constants";
import { getAccessToken } from "./oktaAuth";

// local storage
export const getItem = (key) => {
  if (!isBrowser()) {
    return null;
  }

  return window.localStorage.getItem(key);
};

export const setItem = (key, value) => {
  if (!isBrowser()) {
    return null;
  }

  if (typeof value === "object") {
    value = JSON.stringify(value);
  }

  return window.localStorage.setItem(key, value);
};

// session storage
export const getSessionItem = (key) => {
  if (!isBrowser()) {
    return null;
  }

  return window.sessionStorage.getItem(key);
};

export const setSessionItem = (key, value) => {
  if (!isBrowser()) {
    return null;
  }

  if (typeof value === "object") {
    value = JSON.stringify(value);
  }

  return window.sessionStorage.setItem(key, value);
};

const isAuthModeSupported = (mode) => Object.values(MODE).includes(mode);

export const getAuthMode = () => getCurrentUser()?.mode;

export const setAuthMode = (mode) => {
  if (!isAuthModeSupported(mode)) {
    throw new Error(`Unsupported auth mode ${mode}`);
  }

  setItem(CURRENT_USER, { mode });
};

export const saveOktaTokens = (tokens, mode) => {
  setItem(OKTA_TOKEN, tokens);
  setAuthMode(mode);
};

export const saveMerchantProfile = (profile) => {
  const profiles = [profile];
  setItem(CURRENT_USER, { mode: MODE.DEFAULT, profiles });
};

export const getMatchingProfile = (activeMerchantID, profiles) => {
  const [firstProfile] = profiles || [];

  const matchingProfile =
    profiles?.find(({ merchantId }) => merchantId === activeMerchantID) ||
    firstProfile;

  return matchingProfile;
};

export const saveLinkedProfiles = (profiles) => {
  if (profiles?.length < 1) {
    return;
  }

  const activeMerchantProfile = getMatchingProfile(
    getSessionItem(ACTIVE_MERCHANT_ID),
    profiles
  );

  setItem(CURRENT_USER, { mode: MODE.MERCHANT, profiles });
  setSessionItem(ACTIVE_MERCHANT_ID, activeMerchantProfile?.merchantId);
};

export const getCurrentUser = () => {
  if (!getItem(CURRENT_USER)) {
    return null;
  }

  const user = JSON.parse(getItem(CURRENT_USER));
  return user;
};

/**
 * getToken - returns access token from storage
 * @returns token - string
 */
export const getToken = () => {
  if (getAuthMode() === MODE.DEFAULT) {
    return getItem(TOKEN);
  }

  return getAccessToken();
};

/**
 * getIdToken - returns id token from storage
 * @returns token - string
 */
export const getIdToken = () => {
  if (getAuthMode() === MODE.DEFAULT) {
    return getItem(TOKEN);
  }

  return getIdToken();
};

export const getMerchantProfile = () => {
  const { profiles } = getCurrentUser() || {};

  const activeMerchantProfile = getMatchingProfile(
    getSessionItem(ACTIVE_MERCHANT_ID),
    profiles
  );

  return activeMerchantProfile;
};

export const getMerchantID = () => getMerchantProfile()?.merchantId;

export const changeMerchantProfile = (merchantId) => {
  setSessionItem(ACTIVE_MERCHANT_ID, merchantId);
};

export const getLinkedProfiles = () => getCurrentUser()?.profiles;
