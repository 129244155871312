import React, { useCallback, useState } from "react";

import debounce from "../hoc/debounce";
import AuthContext from "../core/AuthContext";

import {
  ClientOnly,
  Container,
  ErrorMessage,
  ERROR_CODES,
  LoadingMessage,
  OnlineStatus,
} from "../components";

import { logger } from "../core";
import {
  PROMO_SELECT_PLACEHOLDER,
  TRANSACTION_STATUS,
} from "../core/constants";
import {
  Cart,
  Footer,
  GatewayFrame,
  Header,
  Promotions,
  Scene,
  useOrder,
  utils,
} from "../layout/checkout";
import { isPurchaseDisabled } from "../core/config";
import { retry } from "../utils/retry";

const { updateOrder, addStatusChange, getStatusChanges } = utils;

const Checkout = () => {
  const [loading, setLoading] = useState(true);
  const [showCheckoutContent, setShowCheckoutContent] = useState(true);

  const {
    loading: isFetchingOrder,
    disablePayment,
    error,
    order,
    promotions,
    selectedPromotion,
    changeSelectedPromotion,
    setErrorMessage,
    setDisablePayment,
    params,
  } = useOrder();

  const handleCustomerClick = async (payload) => {
    // TODO: Notify API for customer click
    logger.info(`customer clicked, transaction id: ${params.transactionId}`, {
      payload,
      statusChanges: getStatusChanges(),
    });

    setShowCheckoutContent(false);
  };

  const handleCompletePayment = useCallback(
    debounce(async (paymentResponse) => {
      if (loading) {
        return;
      }

      logger.info(
        `payment response from gateway, transaction id: ${params.transactionId}`,
        {
          paymentResponse,
          statusChanges: getStatusChanges(),
        }
      );

      setLoading(true);

      try {
        const { url, status } = await retry({
          fn: () =>
            updateOrder(
              params.transactionToken,
              params.transactionId,
              paymentResponse,
              params.isManual
            ),
          onRetryBegin: (numAttempt) =>
            numAttempt > 0 &&
            logger.error(`handleCompletePayment(): retry #${numAttempt}`, {
              params,
              paymentResponse,
              statusChanges: getStatusChanges(),
            }),
          attempts: 10,
          sleepMs: 200,
          sleepMsDelay: [
            1000,
            2000,
            3000,
            4000,
            5000,
            6000,
            7000,
            8000,
            9000,
            10000,
          ],
        });

        if (
          status.toUpperCase() === TRANSACTION_STATUS.CANCELLED.toUpperCase()
        ) {
          logger.error(`handleCompletePayment(): ${status}`, {
            params,
            paymentResponse,
            statusChanges: getStatusChanges(),
          });

          setErrorMessage(ERROR_CODES.PAYMENT_CANCELLED_AFTER_INITIATE);
          return;
        }

        window.location.replace(url);
      } catch (err) {
        logger.error(`handleCompletePayment(): failed ${err}`, {
          params,
          paymentResponse,
          statusChanges: getStatusChanges(),
        });

        setErrorMessage(ERROR_CODES.PAYMENT_FAILED);
      }
    }),
    [selectedPromotion, params, loading]
  );

  const handlePromotionChange = (value) => {
    setLoading(true);

    if (value === PROMO_SELECT_PLACEHOLDER) {
      setDisablePayment(true);
      return;
    }

    // set loading to false if payment was previously disable.
    // since iframe was already loaded, we just enable the payment
    if (disablePayment) {
      setLoading(false);
    }

    setDisablePayment(false);
    changeSelectedPromotion(value);
  };

  if (isFetchingOrder) {
    return (
      <LoadingMessage message="Getting transaction details while you wait .." />
    );
  }

  if (isPurchaseDisabled) {
    return (
      <ClientOnly>
        <Scene title="Easy checkout" currency={order?.currency}>
          <Scene.FullWidth>
            <ErrorMessage type={ERROR_CODES.PURCHASE_DISABLED} />
          </Scene.FullWidth>
        </Scene>
      </ClientOnly>
    );
  }

  if (error?.message) {
    return (
      <ClientOnly>
        <Scene title="Easy checkout" currency={order?.currency}>
          <Scene.FullWidth>
            <ErrorMessage
              type={error?.message}
              additionalParams={{
                minimumAmount: order?.minimumAmount,
                currency: order?.currency,
                urlCancel: order?.urlCancel,
                transactionId: order?.transactionId,
              }}
            />
          </Scene.FullWidth>
        </Scene>
      </ClientOnly>
    );
  }

  return (
    <ClientOnly>
      <AuthContext.Provider value={params.transactionToken}>
        <Scene currency={order?.currency} loading={!showCheckoutContent}>
          <Scene.Summary>
            <Container gutters>
              <Cart order={order} />
              <Footer order={order} />
            </Container>
          </Scene.Summary>

          <Scene.PaymentDetails>
            <Container gutters>
              <Promotions
                title="Step 1"
                loading={loading}
                promotions={promotions}
                selectedPromotion={selectedPromotion}
                onChange={handlePromotionChange}
                order={order}
              />

              <Header currency={order?.currency} />

              <Container>
                <OnlineStatus onChange={addStatusChange} />
              </Container>

              <GatewayFrame
                hideIframe={disablePayment}
                transactionId={params.transactionId}
                amount={order?.amount}
                promotion={selectedPromotion}
                loading={loading}
                onCustomerClick={handleCustomerClick}
                onResponse={handleCompletePayment}
                onLoaded={() => setLoading(false)}
                onError={() => setErrorMessage(ERROR_CODES.UNEXPECTED_ACTIVITY)}
              />
            </Container>
          </Scene.PaymentDetails>
        </Scene>
      </AuthContext.Provider>
    </ClientOnly>
  );
};

export default Checkout;
